import React from 'react'
import { FieldGuesser, ListGuesser } from '@api-platform/admin'
import { Edit as BaseEdit } from 'ra-ui-materialui/dist/cjs/detail/Edit'
import { Create as BaseCreate } from 'ra-ui-materialui/dist/cjs/detail/Create'
import { required, SimpleForm, TextInput, Filter } from 'react-admin'

import { getDestFolder } from '../utils/aws'
import AwsFileInput from '../inputs/AwsFileInput'

const ListFilter = (props) => (
    <Filter {...props}>
        <TextInput label='Rechercher' source='name' alwaysOn />
    </Filter>
)

const List = (props) => (
    <ListGuesser {...props} filters={<ListFilter />}>
        <FieldGuesser source='name' label='Enseigne' />
    </ListGuesser>
)

const Fields = ({ Guesser, ...props }) => (
    <Guesser {...props}>
        <SimpleForm>
            <TextInput source='name' label='Nom' fullWidth isRequired validate={[required()]} />
            <AwsFileInput
                source='logo.reference'
                label='Logo'
                accept='image/*'
                awsDestFolder={getDestFolder('store-brands')}
            />
        </SimpleForm>
    </Guesser>
)

export const Edit = (props) => <Fields Guesser={BaseEdit} {...props} />

export const Create = (props) => <Fields Guesser={BaseCreate} {...props} />

const CRUD = {
    list: List,
    edit: Edit,
    create: Create,
}

export default CRUD
