const ENTRYPOINT = process.env.REACT_APP_API_DOMAIN

const me = async (token) =>
    fetch(ENTRYPOINT + '/api/me', {
        headers: { Authorization: `Bearer ${token}` },
    })
        .then((res) => {
            return res.json()
        })
        .then((res) => {
            return res
        })
        .catch(() => {
            return null
        })

const isUserAllowed = (roles = []) => {
    return roles.includes('ROLE_ADMIN')
}

const authProvider = {
    login: async ({ username, password }) => {
        const request = new Request(ENTRYPOINT + '/api/authentication_token', {
            method: 'POST',
            body: JSON.stringify({
                email: username,
                password: password,
            }),
            headers: {
                'accept': 'application/json',
                'content-type': 'application/json',
            },
        })

        const token = await fetch(request)
            .then((response) => {
                if (response.status < 200 || response.status >= 300) {
                    throw new Error(response.statusText)
                }

                return response.json()
            })
            .then(({ token }) => {
                return token
            })

        if (!token) {
            return Promise.reject()
        }

        const user = await me(token)

        if (isUserAllowed(user?.roles)) {
            localStorage.setItem('token', token)
            return Promise.resolve()
        }

        return Promise.reject()
    },
    logout: () => {
        localStorage.removeItem('token')

        return Promise.resolve()
    },
    checkError: ({ status }) => {
        if (status === 401 || status === 403) {
            localStorage.removeItem('token')
        }

        return Promise.resolve()
    },
    loginSso: async (token) => {
        const user = await me(token)

        if (isUserAllowed(user?.roles)) {
            localStorage.setItem('token', token)

            return Promise.resolve()
        }

        return Promise.reject()
    },
    checkAuth: () => {
        return localStorage.getItem('token') ? Promise.resolve() : Promise.reject()
    },
    getPermissions: () => {
        const roles = localStorage.getItem('permissions')

        return roles ? Promise.resolve(roles) : Promise.reject()
    },
}

export default authProvider
