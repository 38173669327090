import React from "react";
import PropTypes from "prop-types";
import { ArrayInput, TextInput, SimpleFormIterator } from "react-admin";

const SimpleArrayInput = ({ source, label, labelEntry }) => (
  <ArrayInput source={source} label={label}>
    <SimpleFormIterator key={source}>
      <TextInput
        ful
        label={labelEntry}
        format={(value) => (typeof value === "object" ? "" : value)}
      />
    </SimpleFormIterator>
  </ArrayInput>
);

SimpleArrayInput.defaultProps = {
  addLabel: false,
  label: "Liste",
  labelEntry: "Entrée",
};

SimpleArrayInput.propTypes = {
  label: PropTypes.string,
  labelEntry: PropTypes.string,
  source: PropTypes.string,
};

export default SimpleArrayInput;
