import React from 'react'
import { Layout, AppBar } from 'react-admin'
import { makeStyles } from '@material-ui/core/styles'
import { createTheme } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'

const useStyles = makeStyles({
    logo: {
        maxHeight: 36,
        marginRight: 10,
    },
    title: {
        flex: 1,
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
    },
    spacer: {
        flex: 1,
    },
})

export const theme = createTheme({
    palette: {
        primary: {
            main: '#ef3e35',
        },
        secondary: {
            main: '#5e2a7b',
        },
    },
})

export const CustomAppBar = (props) => {
    const classes = useStyles()

    return (
        <AppBar {...props}>
            <img src='logo.png' alt='Hanes' className={classes.logo} />
            <Typography variant='h6' color='inherit' id='react-admin-title' className={classes.title} />
            <span className={classes.spacer} />
        </AppBar>
    )
}

export const CustomLayout = (props) => <Layout {...props} appBar={CustomAppBar} />
