import { FieldGuesser, ListGuesser } from '@api-platform/admin'
import SystemUpdateAltIcon from '@material-ui/icons/SystemUpdateAlt'
import ClearIcon from '@mui/icons-material/Clear'
import React from 'react'
import {
    Create as BaseCreate,
    Edit as BaseEdit,
    ChipField,
    DateInput,
    Filter,
    ReferenceArrayField,
    ReferenceField,
    SimpleForm,
    SingleFieldList,
    TextField,
    TextInput,
    TopToolbar,
    required,
    useListContext,
} from 'react-admin'
import ImportExportAction from '../customs/actions/ImportExportAction'
import { Button, Box } from '@material-ui/core'

const ListFilter = (props) => {
    const { setFilters } = useListContext(props)

    return (
        <Box style={{ display: 'flex', alignItems: 'end', marginBottom: 15 }}>
            <Filter {...props}>
                <TextInput label='Rechercher' source='reference' alwaysOn />
                <DateInput label='Du' required source='createdAt[after]' alwaysOn />
                <DateInput label='Au' required source='createdAt[before]' alwaysOn />
            </Filter>
            <Button
                variant='text'
                onClick={() => setFilters({}, [])}
                endIcon={<ClearIcon style={{ color: 'red', fontSize: 16 }} />}>
                Reset
            </Button>
        </Box>
    )
}

const ListActions = (props) => {
    const { filterValues } = useListContext(props)
    const isDisabled = !(
        filterValues.createdAt &&
        'after' in filterValues.createdAt &&
        'before' in filterValues.createdAt
    )

    return (
        <TopToolbar>
            <Box style={{ display: 'flex', flexDirection: 'column', alignItems: 'end' }}>
                {isDisabled && (
                    <Box style={{ textAlign: 'right', lineHeight: 0.9, color: '#666', marginBottom: 5 }}>
                        <small>
                            Sélectionner une date de début
                            <br />
                            et de fin pour exporter
                        </small>
                    </Box>
                )}
                <ImportExportAction
                    title='Export photos'
                    btnTitle='Export'
                    url='/api/export/photos'
                    params={{
                        since: filterValues.createdAt?.after,
                        before: filterValues.createdAt?.before,
                    }}
                    Icon={<SystemUpdateAltIcon />}
                    isImport={false}
                    exportedFilename='export_photos_'
                    exportedExtension='xlsx'
                    isDisabled={isDisabled}
                />
            </Box>
        </TopToolbar>
    )
}

const List = (props) => (
    <ListGuesser {...props} actions={<ListActions />} filters={<ListFilter />}>
        <FieldGuesser label='Reference' source='reference' />
        <ReferenceField label='Commercial' source='user' reference='users'>
            <TextField source='lastName' /> <TextField source='firstName' />
        </ReferenceField>
        <ReferenceField label='Zone' source='store' reference='stores'>
            <ReferenceField source='zone'>
                <TextField source='name' />
            </ReferenceField>
        </ReferenceField>
        <ReferenceField label='Magasin' source='store' reference='stores'>
            <TextField source='name' />
        </ReferenceField>
        <ReferenceField label='Ville' source='store' reference='stores' link={false}>
            <ReferenceField source='address' link={false}>
                <TextField source='city' />
            </ReferenceField>
        </ReferenceField>
        <ReferenceField label='Code postal' source='store' reference='stores' link={false}>
            <ReferenceField source='address' link={false}>
                <TextField source='postalCode' />
            </ReferenceField>
        </ReferenceField>
        <ReferenceArrayField label='Natures' reference='natures' source='natures'>
            <SingleFieldList>
                <ChipField source='name' />
            </SingleFieldList>
        </ReferenceArrayField>
        <FieldGuesser source='createdAt' label='Created at' />
        <ReferenceArrayField label='Marque' source='productBrands' reference='product_brands'>
            <SingleFieldList>
                <ChipField source='name' />
            </SingleFieldList>
        </ReferenceArrayField>
        <FieldGuesser source='liked' label='Liked' />
    </ListGuesser>
)

const Fields = ({ Guesser, ...props }) => (
    <Guesser {...props}>
        <SimpleForm>
            <TextInput source='reference' label='Reference' fullWidth isRequired validate={[required()]} />
        </SimpleForm>
    </Guesser>
)

export const Edit = (props) => <Fields Guesser={BaseEdit} {...props} />

export const Create = (props) => <Fields Guesser={BaseCreate} {...props} />

const CRUD = {
    list: List,
    edit: Edit,
    create: Create,
}

export default CRUD
