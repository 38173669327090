import React from 'react'
import {
    Filter,
    ReferenceArrayInput,
    TextField,
    ReferenceField,
    SimpleForm,
    required,
    ReferenceInput,
    CreateButton,
    AutocompleteInput,
    AutocompleteArrayInput,
    TopToolbar,
    TextInput,
} from 'react-admin'

import { FieldGuesser, InputGuesser, ListGuesser } from '@api-platform/admin'
import { Edit as BaseEdit } from 'ra-ui-materialui/dist/cjs/detail/Edit'
import { Create as BaseCreate } from 'ra-ui-materialui/dist/cjs/detail/Create'
import ImportExportAction from '../customs/actions/ImportExportAction'
import SystemUpdateAltIcon from '@material-ui/icons/SystemUpdateAlt'
import AddressInput from '../inputs/AddressInput'

const ListFilter = (props) => {
    return (
        <Filter {...props}>
            <TextInput label='Nom' source='name' alwaysOn />
            <TextInput label='Reference' source='reference' alwaysOn />
            <ReferenceInput source='storeBrand' reference='store_brands' validate={[required()]} perPage={25} alwaysOn>
                <AutocompleteInput
                    optionText='name'
                    filterToQuery={(name) => (name.trim().length >= 3 ? { name } : null)}
                    shouldRenderSuggestions={(searchText) => searchText.trim().length >= 3}
                    perPage={25}
                    label='Enseigne'
                />
            </ReferenceInput>
        </Filter>
    )
}

const ListActions = () => (
    <TopToolbar>
        <CreateButton />

        <ImportExportAction
            title='Import stores'
            description='Select a XLSX file'
            url='/api/import/stores'
            btnTitle='Import'
        />

        <ImportExportAction
            title='Export stores'
            btnTitle='Export'
            url='/api/export/stores'
            Icon={<SystemUpdateAltIcon />}
            isImport={false}
            exportedFilename={'export_stores_'}
            exportedExtension={'xlsx'}
        />
    </TopToolbar>
)

const List = (props) => (
    <ListGuesser {...props} actions={<ListActions />} filters={<ListFilter />}>
        <FieldGuesser source='name' label='Magasin' />
        <FieldGuesser source='reference' label='Reference' />
        <ReferenceField label='Ville' source='address' reference='addresses'>
            <TextField source='city' />
        </ReferenceField>

        <ReferenceField label='Enseigne' source='storeBrand' reference='store_brands'>
            <TextField source='name' />
        </ReferenceField>
        <ReferenceField label='Region' source='region' reference='regions'>
            <TextField source='name' />
        </ReferenceField>
    </ListGuesser>
)

const Fields = ({ Guesser, ...props }) => (
    <Guesser {...props}>
        <SimpleForm>
            <InputGuesser source='name' label='Magasin' fullWidth />
            <InputGuesser source='reference' label='Reference' fullWidth />
            <ReferenceInput
                label='Enseigne'
                source='storeBrand'
                reference='store_brands'
                validate={[required()]}
                perPage={25}
                alwaysOn
            >
                <AutocompleteInput
                    optionText='name'
                    filterToQuery={(name) => (name.trim().length >= 3 ? { name } : null)}
                    shouldRenderSuggestions={(searchText) => searchText.trim().length >= 3}
                    perPage={25}
                    fullWidth
                />
            </ReferenceInput>
            <ReferenceInput label='Region' source='region' reference='regions' validate={[required()]} perPage={25}>
                <AutocompleteInput
                    optionText='name'
                    filterToQuery={(name) => (name.trim().length >= 3 ? { name } : null)}
                    shouldRenderSuggestions={(searchText) => searchText.trim().length >= 3}
                    perPage={25}
                    fullWidth
                />
            </ReferenceInput>
            <ReferenceInput label='Zone' source='zone' reference='zones' validate={[required()]} perPage={25}>
                <AutocompleteInput
                    optionText='name'
                    filterToQuery={(name) => (name.trim().length >= 3 ? { name } : null)}
                    shouldRenderSuggestions={(searchText) => searchText.trim().length >= 3}
                    perPage={25}
                    fullWidth
                />
            </ReferenceInput>
            <ReferenceInput label='Secteur' source='sector' reference='sectors' validate={[required()]} perPage={25}>
                <AutocompleteInput
                    optionText='name'
                    filterToQuery={(name) => (name.trim().length >= 3 ? { name } : null)}
                    shouldRenderSuggestions={(searchText) => searchText.trim().length >= 3}
                    perPage={25}
                    fullWidth
                />
            </ReferenceInput>
            <ReferenceArrayInput label='Utilisateurs' source='users' reference='users' fullWidth>
                <AutocompleteArrayInput
                    optionText={({ lastName, firstName }) => `${lastName} ${firstName}`}
                    filterToQuery={(search) => (search.trim().length >= 3 ? { search } : null)}
                    shouldRenderSuggestions={(searchText) => searchText.trim().length >= 3}
                    perPage={25}
                    fullWidth
                    style={{ marginTop: 20 }}
                />
            </ReferenceArrayInput>
            <AddressInput source='address' label='Adresse' />
        </SimpleForm>
    </Guesser>
)

export const Edit = (props) => <Fields Guesser={BaseEdit} {...props} />

export const Create = (props) => <Fields Guesser={BaseCreate} {...props} />

const CRUD = {
    list: List,
    edit: Edit,
    create: Create,
}

export default CRUD
