import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import { useEffect, useState } from 'react'
import { useInput } from 'react-admin'

const RoleInput = ({ helperText, ...props }) => {
    const [role, setRole] = useState()

    const handleChange = (event) => {
        setRole(event.target.value)
    }

    const { field } = useInput(props)

    useEffect(() => {
        field.onChange([role])
    }, [role])

    const roles = [
        { id: 'ROLE_MANAGER', name: 'Manager' },
        { id: 'ROLE_ADMIN', name: 'Administrateur' },
        { id: 'ROLE_SALESMAN', name: 'Commercial' },
        { id: 'ROLE_DBI', name: 'Collaborateur DBI' },
    ]

    return (
        <FormControl fullWidth>
            <InputLabel id='demo-simple-select-label'>Rôle *</InputLabel>
            <Select value={role} label='Rôle' {...field} onChange={handleChange}>
                {roles.map(({ id, name }) => (
                    <MenuItem value={id}>{name}</MenuItem>
                ))}
            </Select>
        </FormControl>
    )
}

export default RoleInput
