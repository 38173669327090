import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { FileField, FileInput, ImageField, useGetOne, LinearProgress, useRecordContext } from 'react-admin'
import { getUrl } from '../utils/aws'

const AwsFileInput = ({
    source,
    reference,
    accept,
    awsDestFolder = '',
    srcPath = 'src',
    titlePath = 'title',
    ...props
}) => {
    const record = useRecordContext()

    const [fileData, setFileData] = useState(null)
    const [loading, setLoading] = useState(true)

    const PreviewField = accept && accept.includes('image') ? ImageField : FileField

    const { data, isLoading } = useGetOne(reference, { id: record?.logo })

    useEffect(() => {
        const referenceS3 = data?.reference

        if (referenceS3) {
            setLoading(true)
            getUrl(referenceS3, awsDestFolder)
                .then((url) => {
                    setFileData({
                        src: url,
                    })
                    setLoading(false)
                })
                .catch(() => {})
        } else {
            setLoading(false)
        }
    }, [data])

    return (
        <FileInput
            record={record}
            source={source}
            reference={reference}
            {...props}
            format={(value) => {
                if (value && value.rawFile) return value

                return {
                    src: fileData?.src,
                    title: fileData?.titlePath,
                }
            }}
        >
            {!loading ? <PreviewField source='src' /> : <LinearProgress />}
        </FileInput>
    )
}

AwsFileInput.defaultProps = {
    label: 'File',
    srcPath: 'url',
    titlePath: 'reference',
}

AwsFileInput.propTypes = {
    label: PropTypes.string,
}

export default AwsFileInput
