// import { FieldGuesser, ListGuesser } from '@api-platform/admin'
import React from 'react'
import {
    TextInput,
    DateField,
    SimpleForm,
    Create as BaseCreate,
    Edit as BaseEdit,
    required,
    Filter,
    DateInput,
    ReferenceInput,
    AutocompleteInput,
} from 'react-admin'

import SimpleArrayInput from '../inputs/SimpleArrayInput'
import { FieldGuesser, ListGuesser } from '@api-platform/admin'

const ListFilter = (props) => (
    <Filter {...props}>
        <TextInput label='Rechercher' source='name;description' alwaysOn />
        <DateInput label='Après le' source='startAt[strictly_after]' />
        <DateInput label='Avant le' source='endAt[strictly_after]' />
    </Filter>
)

const List = (props) => (
    <ListGuesser {...props} filters={<ListFilter />}>
        <FieldGuesser source='name' label='Mission' />
        <DateField source='startAt' label='Début' />
        <DateField source='endAt' label='Fin' />
    </ListGuesser>
)

const Fields = ({ Guesser, ...props }) => (
    <Guesser {...props}>
        <SimpleForm>
            <TextInput source='name' label='Titre de la mission' fullWidth isRequired validate={[required()]} />
            <TextInput
                source='description'
                label='Description de la mission'
                validate={[required()]}
                fullWidth
                multiline
            />
            <DateInput label='Début' source='startAt' validate={[required()]} fullWidth />
            <DateInput label='Fin' source='endAt' validate={[required()]} fullWidth />
            <ReferenceInput label='Season' source='season' reference='seasons' validate={[required()]} perPage={99999}>
                <AutocompleteInput
                    optionText='name'
                    filterToQuery={(name) => (name.trim().length >= 2 ? { name } : null)}
                    shouldRenderSuggestions={(searchText) => searchText.trim().length >= 2}
                    perPage={25}
                    fullWidth
                />
            </ReferenceInput>
            <SimpleArrayInput source='productDescription' label={'Produits'} labelEntry={'Produit'} />
        </SimpleForm>
    </Guesser>
)

export const Edit = (props) => <Fields Guesser={BaseEdit} {...props} />

export const Create = (props) => <Fields Guesser={BaseCreate} {...props} />

const CRUD = {
    list: List,
    edit: Edit,
    create: Create,
}

export default CRUD
