import React from "react";

import UserActiveButtonWithConfirmation from "./UserActiveButtonWithConfirmation";

import { useRecordContext } from "react-admin";

const DisableOrRestoreButtonFactory = () => {
  const record = useRecordContext();

  if (record?.active) {
    return (
      <UserActiveButtonWithConfirmation
        path="disable"
        label="Désactiver"
        record={record}
      />
    );
  }

  return (
    <UserActiveButtonWithConfirmation
      path="restore"
      label="Restaurer"
      record={record}
    />
  );
};

export default DisableOrRestoreButtonFactory;
