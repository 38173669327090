import { FieldGuesser, ListGuesser } from '@api-platform/admin'

import React from 'react'
import { TextInput, SimpleForm, BooleanInput, Create as BaseCreate, Edit as BaseEdit, required, Filter } from 'react-admin'

const ListFilter = (props) => (
    <Filter {...props}>
        <TextInput label='Rechercher' source='name' alwaysOn />
    </Filter>
)

const List = (props) => (
    <ListGuesser {...props} filters={<ListFilter />}>
        <FieldGuesser source='name' label='Saison' />
        <FieldGuesser source='active' />
    </ListGuesser>
)

const Fields = ({ Guesser, ...props }) => (
    <Guesser {...props}>
        <SimpleForm>
            <TextInput source='name' label='Saison' fullWidth isRequired validate={[required()]} />
            <BooleanInput label='Active' source='active' />
        </SimpleForm>
    </Guesser>
)

export const Edit = (props) => <Fields Guesser={BaseEdit} {...props} />

export const Create = (props) => <Fields Guesser={BaseCreate} {...props} />

const CRUD = {
    list: List,
    edit: Edit,
    create: Create,
}

export default CRUD
