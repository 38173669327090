import { Button, CardActions, Link } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import React, { useEffect } from 'react'
import { Login, LoginForm, useAuthProvider } from 'react-admin'

const SSO_URL = `${process.env.REACT_APP_API_DOMAIN}/saml/login`

const styles = {
    avatar: {
        background: 'url(./images/logo.png)',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'contain',
        height: 80,
    },
    icon: { display: 'none' },
}

const CustomLoginPage = (props) => {
    const urlParams = new URLSearchParams(window.location.search)
    const authProvider = useAuthProvider()
    const hasToken = urlParams.has('token')

    useEffect(() => {
        if (hasToken) {
            authProvider.loginSso(urlParams.get('token')).then(() => {
                window.location.replace('/')
            })
        }
    }, [authProvider, hasToken, urlParams])

    return (
        <Login backgroundImage='./images/wave_bg.svg' {...props}>
            {!hasToken && (
                <>
                    <LoginForm />
                    <CardActions>
                        <Button
                            component={Link}
                            href={`${SSO_URL}?redirect_uri=${window.location.href.split('?')[0]}`}
                            variant='contained'
                            type='button'
                            color='secondary'
                            style={{ width: '100%' }}>
                            SSO
                        </Button>
                    </CardActions>
                </>
            )}
        </Login>
    )
}

export default withStyles(styles)(CustomLoginPage)
