import React from "react";
import { CustomLayout, theme } from "./customs/CustomTheme";
import CustomLoginPage from "./customs/CustomLoginPage";
import { ResourceGuesser } from "@api-platform/admin";
import Admin from "./Admin";
import MissionIcon from "@material-ui/icons/Assignment";
import Mission from "./resources/Mission";
import Season from "./resources/Season";
import Zone from "./resources/Zone";
import Tag from "./resources/Tag";
import Marque from "./resources/Marque";
import Nature from "./resources/Nature";
import User from "./resources/User";
import Category from "./resources/Category";
import Store from "./resources/Store";
import StoreBrand from "./resources/StoreBrand";
import Media from "./resources/Media";

import SeasonIcon from "@material-ui/icons/WbSunny";
import ZoneIcon from "@material-ui/icons/Extension";
import TagIcon from "@material-ui/icons/Flare";
import NatureIcon from "@material-ui/icons/Eco";
import ProductBrandIcon from "@material-ui/icons/Loyalty";
import CategoryIcon from "@material-ui/icons/ListAlt";
import UserIcon from "@material-ui/icons/Group";
import StoreBrandIcon from "@material-ui/icons/LocationCity";
import StoreIcon from "@material-ui/icons/Store";
import CropOriginalIcon from "@material-ui/icons/CropOriginal";

const App = () => {
  return (
    <Admin
      theme={theme}
      loginPage={CustomLoginPage}
      title="Hanes admin"
      layout={CustomLayout}
    >
      <ResourceGuesser
        icon={MissionIcon}
        options={{ label: "Missions" }}
        name="missions"
        {...Mission}
      />
      <ResourceGuesser
        icon={StoreIcon}
        options={{ label: "Magasins" }}
        name="stores"
        {...Store}
      />
      <ResourceGuesser
        icon={StoreBrandIcon}
        options={{ label: "Enseigne" }}
        name="store_brands"
        {...StoreBrand}
      />
      <ResourceGuesser
        icon={SeasonIcon}
        options={{ label: "Saisons" }}
        name="seasons"
        {...Season}
      />
      <ResourceGuesser
        icon={CategoryIcon}
        options={{ label: "Categories" }}
        name="categories"
        {...Category}
      />
      <ResourceGuesser
        icon={ProductBrandIcon}
        options={{ label: "Marques" }}
        name="product_brands"
        {...Marque}
      />
      <ResourceGuesser
        icon={NatureIcon}
        options={{ label: "Natures" }}
        name="natures"
        {...Nature}
      />
      <ResourceGuesser
        icon={TagIcon}
        options={{ label: "Tags" }}
        name="tags"
        {...Tag}
      />
      <ResourceGuesser
        icon={UserIcon}
        options={{ label: "Utilisateurs" }}
        name="users"
        {...User}
      />
      <ResourceGuesser
        icon={ZoneIcon}
        options={{ label: "Zones" }}
        name="zones"
        {...Zone}
      />
      <ResourceGuesser
        icon={CropOriginalIcon}
        options={{ label: "Media" }}
        name="media"
        {...Media}
      />
    </Admin>
  );
};

export default App;
