import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogTitle from '@material-ui/core/DialogTitle'
import IconCancel from '@material-ui/icons/Cancel'
import RestoreIcon from '@material-ui/icons/Restore'
import ClearIcon from '@material-ui/icons/Clear'
import PropTypes from 'prop-types'
import React, { Fragment, useState } from 'react'
import { Button, useRefresh } from 'react-admin'
import axios from 'axios'
const ENTRYPOINT = process.env.REACT_APP_API_DOMAIN

const RestoreUserButtonWithConfirmation = ({ record, path, label = '' }) => {
    const [showDialog, setShowDialog] = useState(false)

    const onOpen = () => setShowDialog(true)

    const onClose = () => setShowDialog(false)
    const refresh = useRefresh()

    const handleRestore = () => {
        onClose()

        axios
            .post(`${ENTRYPOINT}${record.id}/${path}`, null, {
                headers: {
                    Authorization: `Bearer ${window.localStorage.getItem('token')}`,
                },
            })
            .then(() => {
                refresh()
            })
    }

    return (
        <Fragment>
            <Button onClick={onOpen} label={label} className={'s'} key='button'>
                {path === 'restore' ? <RestoreIcon /> : <ClearIcon />}
            </Button>
            <Dialog fullWidth open={showDialog} onClose={onClose} aria-label='Are you sure?'>
                <DialogTitle>Êtes-vous sûrs ?</DialogTitle>
                <DialogActions>
                    <Button onClick={handleRestore} label={label} className='d' key='button'>
                        {path === 'restore' ? <RestoreIcon /> : <ClearIcon />}
                    </Button>
                    <Button label='ra.action.cancel' onClick={onClose}>
                        <IconCancel />
                    </Button>
                </DialogActions>
            </Dialog>
        </Fragment>
    )
}

RestoreUserButtonWithConfirmation.propTypes = {
    classes: PropTypes.object,
    className: PropTypes.string,
    label: PropTypes.string,
    record: PropTypes.object,
    translate: PropTypes.func,
}

export default RestoreUserButtonWithConfirmation
