import polyglotI18nProvider from 'ra-i18n-polyglot'
import en from 'ra-language-english'
import fr from 'ra-language-french'

const translations = { en, fr }

export const i18nProvider = polyglotI18nProvider(
    (locale) => translations[locale],
    'fr', // default locale
    [
        { locale: 'en', name: 'Anglais' },
        { locale: 'fr', name: 'Français' },
    ]
)
