import {
    fetchHydra as baseFetchHydra,
    hydraDataProvider as baseHydraDataProvider,
    useIntrospection,
} from '@api-platform/admin'
import { parseHydraDocumentation } from '@api-platform/api-doc-parser'
import React from 'react'
import { Navigate, Route } from 'react-router-dom'
import authProvider from './authProvider'
import { i18nProvider } from './i18nProvider'
import { getDestFolder } from './utils/aws'
import handleFilesUpload from './utils/uploader'

const ENTRYPOINT = process.env.REACT_APP_API_DOMAIN

const getHeaders = () =>
    localStorage.getItem('token')
        ? {
              Authorization: `Bearer ${localStorage.getItem('token')}`,
          }
        : {}

const fetchHydra = (url, options = {}) =>
    baseFetchHydra(url, {
        ...options,
        headers: getHeaders,
    })

const RedirectToLogin = () => {
    const introspect = useIntrospection()

    if (localStorage.getItem('token')) {
        introspect()

        return <></>
    }

    return <Navigate to='/login' />
}

const apiDocumentationParser = async () => {
    try {
        return await parseHydraDocumentation(`${ENTRYPOINT}/api`, { headers: getHeaders })
    } catch (result) {
        const { api, response, status } = result

        if (status !== 401 || !response) {
            throw result
        }

        localStorage.removeItem('token')

        return {
            api,
            response,
            status,
            customRoutes: [<Route key='/' path='/' component={RedirectToLogin} />],
        }
    }
}

const baseDataProvider = baseHydraDataProvider({
    entrypoint: `${ENTRYPOINT}/api`,
    httpClient: fetchHydra,
    apiDocumentationParser,
    useEmbedded: false,
})

const dataProvider = {
    ...baseDataProvider,
    create: async (resource, params) => {
        params.data = await handleFilesUpload(params.data, getDestFolder(resource))
        
        return baseDataProvider.create(resource, params)
    },
    update: async (resource, params) => {
        params.data = await handleFilesUpload(params.data, getDestFolder(resource))

        return baseDataProvider.update(resource, params)
    },
}

const AdminLoader = (props) => {
    if (typeof window !== 'undefined') {
        const { HydraAdmin } = require('@api-platform/admin')

        return (
            <HydraAdmin
                dataProvider={dataProvider}
                authProvider={authProvider}
                entrypoint={window.origin}
                i18nProvider={i18nProvider}
                {...props}
            />
        )
    }

    return <></>
}

const Admin = (props) => <AdminLoader {...props} />

export default Admin
