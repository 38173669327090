import aws from 'aws-sdk'

import uuidv4 from './uuid'

// only support uploading store brand logos to aws

const BUCKET_NAME = process.env.REACT_APP_AWS_BUCKET_NAME
const REGION = process.env.REACT_APP_AWS_REGION
export const FOLDER_DEV = 'test'

// https://docs.aws.amazon.com/AWSJavaScriptSDK/latest/AWS/S3.html
const s3 = new aws.S3({
    accessKeyId: process.env.REACT_APP_AWS_ID,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET,
    region: REGION,
})

export const uploadFile = (file, destFolder) => {
    const extention = file.name.split('.').pop()

    const key = getKey(destFolder, `${uuidv4()}.${extention}`)
    return s3
        .upload({
            Bucket: BUCKET_NAME,
            Key: key,
            ContentType: file.type,
            Body: file,
        })
        .promise()
}

const getKey = (folder, path) => `${folder}/${path}`

export const getUrl = async (path, folder) =>
    s3.getSignedUrlPromise('getObject', {
        Bucket: BUCKET_NAME,
        Key: getKey(folder, path),
    })

export const getObject = (path, folder) =>
    s3
        .getObject({
            Bucket: BUCKET_NAME,
            Key: getKey(folder, path),
        })
        .promise()

const IS_PROD_ENV = process.env.NODE_ENV === 'production'

export const getDestFolder = (folder = 'store-brands') => {
    return IS_PROD_ENV ? folder : FOLDER_DEV
}
