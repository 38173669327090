import { FieldGuesser, ListGuesser } from '@api-platform/admin'
import React from 'react'
import {
    FunctionField,
    ShowButton,
    EditButton,
    List as BaseList,
    SimpleForm,
    Create as BaseCreate,
    Edit as BaseEdit,
    TextInput,
    Filter,
    ReferenceInput,
    AutocompleteInput,
    TopToolbar,
    Datagrid,
    PasswordInput,
    required,
    DeleteWithConfirmButton,
    ReferenceArrayInput,
    AutocompleteArrayInput,
    CreateButton,
    BooleanInput,
} from 'react-admin'
import ImportExportAction from '../customs/actions/ImportExportAction'
import SystemUpdateAltIcon from '@material-ui/icons/SystemUpdateAlt'
import RoleInput from '../inputs/RoleInput'
import DisableOrRestoreButtonFactory from './../customs/actions/DisableOrRestoreButtonFactory'

const choices = {
    ROLE_SALESMAN: 'Commercial',
    ROLE_MANAGER: 'Manager',
    ROLE_DBI: 'Collaborateur DBI',
    ROLE_ADMIN: 'Administrateur',
    ROLE_SUPER_ADMIN: 'Super admin',
}

const ListActions = () => (
    <TopToolbar>
        <CreateButton />

        <ImportExportAction
            title='Import users'
            description='Select a XLSX file'
            url='/api/import/users'
            btnTitle='Import'
        />

        <ImportExportAction
            title='Export users'
            btnTitle='Export'
            url='/api/export/users'
            Icon={<SystemUpdateAltIcon />}
            isImport={false}
            exportedFilename={'export_users_'}
            exportedExtension={'xlsx'}
        />
    </TopToolbar>
)

const ListFilter = (props) => (
    <Filter {...props}>
        <TextInput label='Nom' source='lastName' alwaysOn />
        <TextInput label='Prénom' source='firstName' alwaysOn />
        <TextInput label='Email' source='email' alwaysOn />
        <BooleanInput
            label='Uniquement les utilisateurs actifs'
            alwaysOn
            source='active'
            parse={(v) => {
                return !v ? null : true
            }}
        />
    </Filter>
)

const List = (props) => {
    return (
        <BaseList {...props} actions={<ListActions />} filters={<ListFilter />}>
            <Datagrid>
                <FieldGuesser source='lastName' label='Nom' />
                <FieldGuesser source='firstName' label='Prénom' />
                <FieldGuesser source='email' label='Email' />
                <FieldGuesser source='active' label='Active' />
                <FunctionField
                    label='Rôle'
                    render={(record) => {
                        return choices[[...record.roles].shift()]
                    }}
                />

                <ShowButton />
                <EditButton />
                <DisableOrRestoreButtonFactory />
                <DeleteWithConfirmButton />
            </Datagrid>
        </BaseList>
    )
}

const Fields = ({ Guesser, ...props }) => (
    <Guesser {...props}>
        <SimpleForm>
            <TextInput source='lastName' label='Nom' fullWidth isRequired validate={[required()]} />
            <TextInput source='firstName' label='Prénom' fullWidth isRequired validate={[required()]} />
            <TextInput source='email' label='Email' fullWidth isRequired validate={[required()]} />
            <PasswordInput source='password' label='Mot de passe' fullWidth />
            <ReferenceInput
                label='Region'
                source='region'
                reference='regions'
                isRequired
                validate={[required()]}
                perPage={99999}>
                <AutocompleteInput
                    optionText='name'
                    isRequired
                    filterToQuery={(name) => (name.trim().length >= 3 ? { name } : null)}
                    shouldRenderSuggestions={(searchText) => searchText.trim().length >= 3}
                    perPage={25}
                    fullWidth
                />
            </ReferenceInput>
            <ReferenceInput label='Zone' source='zone' reference='zones' validate={[required()]} perPage={99999}>
                <AutocompleteInput optionText='name' filterToQuery={(name) => ({ name })} perPage={25} fullWidth />
            </ReferenceInput>
            <RoleInput label='Role*' source='roles' validate={[required()]} />
            <ReferenceArrayInput source='stores' reference='stores' label='Magasins' perPage={99999} fullWidth>
                <AutocompleteArrayInput
                    optionText='name'
                    filterToQuery={(name) => (name.trim().length >= 3 ? { name } : null)}
                    shouldRenderSuggestions={(searchText) => searchText.trim().length >= 3}
                    perPage={25}
                    fullWidth
                    style={{ marginTop: 20 }}
                />
            </ReferenceArrayInput>
        </SimpleForm>
    </Guesser>
)

export const Edit = (props) => <Fields Guesser={BaseEdit} {...props} />

export const Create = (props) => <Fields Guesser={BaseCreate} {...props} />

const CRUD = {
    list: List,
    edit: Edit,
    create: Create,
}

export default CRUD
