import { useRecordContext } from 'react-admin'
import TextField from '@mui/material/TextField'
import { useInput, useGetOne } from 'react-admin'
import { CircularProgress, MenuItem } from '@material-ui/core'
import { Fragment, useRef } from 'react'
import Grid from '@mui/material/Grid'

const TextInput = ({ parse = null, ...props }) => {
    const { defaultValue, ...rest } = props
    const {
        field,
        fieldState: { isTouched, invalid, error },
        formState: { isSubmitted },
        isRequired,
    } = useInput({
        ...props,
    })

    const onChange = (event) => {
        field.onChange(parse ? parse(event.target.value) : event.target.value)
    }

    const ref = useRef()
    return (
        <TextField
            {...field}
            error={(isTouched || isSubmitted) && invalid}
            helperText={(isTouched || isSubmitted) && invalid ? error : ''}
            required={isRequired}
            fullWidth
            {...rest}
            ref={ref}
            onChange={onChange}
        />
    )
}

const ChoiceInput = ({ choices, ...props }) => {
    const { ...rest } = props
    const {
        field,
        fieldState: { isTouched, invalid, error },
        formState: { isSubmitted },
        isRequired,
    } = useInput({
        ...props,
    })

    return (
        <TextField
            {...field}
            error={(isTouched || isSubmitted) && invalid}
            helperText={(isTouched || isSubmitted) && invalid ? error : ''}
            required={isRequired}
            fullWidth
            select
            {...rest}>
            {choices.map(({ id, name }) => (
                <MenuItem key={id} value={id}>
                    {name}
                </MenuItem>
            ))}
        </TextField>
    )
}

const COUNTRIES = [
    { id: 'FR', name: 'France' },
    { id: 'BE', name: 'Belgique' },
]

const AddressFields = ({ data = null }) => (
    <>
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <TextInput
                    size='medium'
                    name='address.city'
                    source=''
                    label='Ville'
                    required
                    defaultValue={data?.city}
                />
            </Grid>
            <Grid item xs={12}>
                <TextInput name='address.street' source='' label='Rue' required defaultValue={data?.street} />
            </Grid>
            <Grid item xs={12}>
                <TextInput
                    name='address.postalCode'
                    source=''
                    label='Code postal'
                    required
                    defaultValue={data?.postalCode}
                />
            </Grid>
            <Grid item xs={12}>
                <ChoiceInput
                    name='address.country'
                    source=''
                    label='Pays'
                    defaultValue={data?.country}
                    required
                    choices={COUNTRIES}
                />
            </Grid>
            <Grid item xs={12}>
                <TextInput
                    name='address.latitude'
                    source=''
                    label='Latitude'
                    type='number'
                    required
                    parse={(value) => parseFloat(value)}
                    defaultValue={data?.latitude}
                />
            </Grid>
            <Grid item xs={12}>
                <TextInput
                    name='address.longitude'
                    source=''
                    label='Longitude'
                    type='number'
                    parse={(value) => parseFloat(value)}
                    required
                    defaultValue={data?.longitude}
                />
            </Grid>
        </Grid>
    </>
)

const AddressInput = ({ reference }) => {
    const record = useRecordContext()
    const isEdit = record !== undefined
    const { data, isLoading } = useGetOne(reference, { id: record?.address }, { enabled: isEdit })

    return <Fragment>{isLoading && isEdit ? <CircularProgress size={30} /> : <AddressFields data={data} />}</Fragment>
}

export default AddressInput
