import {
    Box,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    LinearProgress,
} from '@material-ui/core'
import IconPublish from '@material-ui/icons/Publish'
import axios from 'axios'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { Button, FileField, FileInput, required, useNotify } from 'react-admin'
import { useForm, FormProvider } from 'react-hook-form'

const ENTRYPOINT = process.env.REACT_APP_API_DOMAIN

const send = async (url, urlParamKey, params, isImport, onSuccess, data) => {
    const requestUrl = getRequestUrl(url, urlParamKey, data)
    const headers = {
        Authorization: `Bearer ${window.localStorage.getItem('token')}`,
    }
    const formData = new FormData()

    Object.keys(data).forEach((key) => {
        formData.append(key, key === 'file' ? data[key].rawFile : data[key])
    })

    const request = isImport
        ? axios.post(`${ENTRYPOINT}${requestUrl}`, formData, {
              headers: { ...headers, 'Content-Type': 'multipart/form-data' },
          })
        : axios.get(`${ENTRYPOINT}${requestUrl}`, {
              params,
              headers,
              responseType: 'arraybuffer',
          })

    return request
        .then(({ status, data, headers }) => {
            if (status === 200 || status === 201 || status === 202) {
                onSuccess(data, headers)
            }
        })
        .catch((error) => {
            throw error.response.data.message
        })
}

const ImportExportAction = ({
    url,
    title = 'Import',
    btnTitle = 'Import',
    description = 'Select a file',
    Icon = <IconPublish />,
    urlParamKey = null, // key of only 1 param for the API url
    isImport = true,
    exportedFilename = null,
    exportedExtension = null,
    params = null,
    isDisabled,
    children,
}) => {
    const [showDialog, setShowDialog] = useState(false)
    const [error, setError] = useState(null)
    const [loading, setLoading] = useState(false)
    const notify = useNotify()
    const handleClick = () => setShowDialog(true)
    const handleCloseClick = () => setShowDialog(false)
    const handleSubmit = async (data) => {
        setLoading(true)

        send(
            url,
            urlParamKey,
            params,
            isImport,
            (response, headers) => {
                notify('Success', 'info')
                setShowDialog(false)
                setLoading(false)

                // Download file
                if (!isImport) {
                    const blob = new Blob([response], { type: headers['content-type'] })
                    const url = window.URL.createObjectURL(blob)
                    const anchor = document.createElement('a')
                    const date = moment().format('YYYYMMDD_hmm')

                    anchor.download = `${exportedFilename}${date}.${exportedExtension}`
                    anchor.href = url
                    anchor.click()
                }
            },
            data
        ).catch((error) => {
            notify('Error', 'error')
            setError(error)
            setLoading(false)
        })
    }

    const methods = useForm()

    return (
        <>
            <Button
                disabled={isDisabled}
                onClick={!isImport && children === undefined ? handleSubmit : handleClick}
                label={btnTitle}>
                {Icon}
            </Button>
            <Dialog fullWidth open={showDialog} onClose={handleCloseClick}>
                <DialogTitle>{title}</DialogTitle>
                <DialogContent>
                    <DialogContentText>{description}</DialogContentText>
                    {loading && <LinearProgress />}
                    {error && (
                        <Box border={1} borderRadius={5} color='error.main' p={2}>
                            {`${error}`}
                        </Box>
                    )}
                    <FormProvider {...methods}>
                        <form onSubmit={methods.handleSubmit(handleSubmit)}>
                            {children}
                            {isImport && (
                                <FileInput label='File' source='file' validate={required()} required>
                                    <FileField source='src' title='title' />
                                </FileInput>
                            )}
                            <button type='submit'>Send</button>
                        </form>
                    </FormProvider>
                </DialogContent>
                <DialogActions></DialogActions>
            </Dialog>
        </>
    )
}

const getRequestUrl = (url, urlParamKey, data) => (typeof url === 'function' ? url(data[urlParamKey]) : url)

export default ImportExportAction
