import isObject from 'lodash/isObject'
import { uploadFile } from './aws'
import { getFilenameFromPath } from './path'

/**
 * Handle rawFile (imageInput), post on dedicated endpoint and get reference
 */
const handleFilesUpload = async (data, resource) => {
    const findDeep = async (object) => {
        if (object) {
            for (const key in object) {
                if (key === 'rawFile') {
                    object = await postFile(object[key], resource)
                } else if (isObject(object[key])) {
                    object[key] = await findDeep(object[key])
                }
            }
        }

        return object
    }
    
    data = await findDeep(data)
    
    if (data.logo && 
        ((data.logo.reference === undefined) || (data.logo.reference === null)) ){
            data.logo = null
    }
    
    return data
}

const postFile = async (file, destFolder) => {
    let awsRightDestFolder = ''
    if(destFolder === 'store_brands'){
        awsRightDestFolder = 'store-brands'
    }
    return uploadFile(file, awsRightDestFolder)
        .then((response) => getFilenameFromPath(response.Key))
        .catch(() => {
            return null
        })

    // const formData = new FormData()

    // formData.append('file', new File([file], file.name))

    // return axios
    //     .post(`${ENTRYPOINT}/api/media`, formData, {
    //         headers: {
    //             'Content-Type': 'multipart/form-data',
    //             'Authorization': `Bearer ${window.localStorage.getItem('token')}`,
    //         },
    //     })
    //     .then((response) => response.data['@id'])
    //     .catch((error) => {
    //         console.error(error)
    //     })
}

export default handleFilesUpload
